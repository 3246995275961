document.addEventListener('DOMContentLoaded', function() {
    const mobileMenuButton = document.getElementById('mobile-menu-button');
    const mainNav = document.getElementById('main-nav');
    const menuItems = mainNav.querySelectorAll('li > a');
    const mobileMenuOverlay = document.getElementById('mobile-menu-overlay');
    const body = document.body;
    
    // Toggle mobile menu 
    mobileMenuButton.addEventListener('click', function() {
      body.classList.toggle('mobile-menu-open');
      const isExpanded = mobileMenuButton.getAttribute('aria-expanded') === 'true';
      mobileMenuButton.setAttribute('aria-expanded', !isExpanded);
    });

    // Handle mobile submenu toggles
    const mobileSubmenuToggles = document.querySelectorAll('.submenu-toggle');
    mobileSubmenuToggles.forEach(toggle => {
      toggle.addEventListener('click', function() {
        const parent = this.closest('.mobile-menu-item');
        const submenu = parent.querySelector('.mobile-submenu');
        
        // Toggle active class for the arrow icon
        this.classList.toggle('active');
        
        // Toggle submenu height for smooth animation
        if (submenu.style.maxHeight) {
          submenu.style.maxHeight = null;
        } else {
          submenu.style.maxHeight = submenu.scrollHeight + "px";
        }
      });
    });
  
    // Mobile menu links with children
    const mobileMenuLinks = document.querySelectorAll('.mobile-menu-item a[data-has-children="true"]');
    mobileMenuLinks.forEach(link => {
      link.addEventListener('click', function(e) {
        // Only prevent default if submenu exists
        e.preventDefault();
        const parent = this.closest('.mobile-menu-item');
        const toggle = parent.querySelector('.submenu-toggle');
        if (toggle) {
          toggle.click(); // Trigger the submenu toggle
        }
      });
    });
  
    // Handle submenu toggling and accessibility for desktop
    menuItems.forEach(item => {
      const submenu = item.nextElementSibling;
      if (submenu) {
        item.addEventListener('click', function(e) {
          e.preventDefault();
          const expanded = this.getAttribute('aria-expanded') === 'true' || false;
          this.setAttribute('aria-expanded', !expanded);
          submenu.classList.toggle('hidden');
        });
  
        // Close submenu when clicking outside
        document.addEventListener('click', function(e) {
          if (!item.contains(e.target) && !submenu.contains(e.target)) {
            item.setAttribute('aria-expanded', 'false');
            submenu.classList.add('hidden');
          }
        });
  
        // Keyboard navigation
        item.addEventListener('keydown', function(e) {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            this.click();
          }
        });
  
        submenu.querySelectorAll('a').forEach(subItem => {
          subItem.setAttribute('role', 'menuitem');
          subItem.addEventListener('keydown', function(e) {
            if (e.key === 'Escape') {
              e.preventDefault();
              item.focus();
              item.click();
            }
          });
        });
      }
    });
  
    // Close mobile menu when clicking on a link that doesn't have children
    const mobileSimpleLinks = document.querySelectorAll('.mobile-menu-item a:not([data-has-children="true"])');
    mobileSimpleLinks.forEach(link => {
      link.addEventListener('click', function() {
        // Close mobile menu when navigating
        body.classList.remove('mobile-menu-open');
        mobileMenuButton.setAttribute('aria-expanded', 'false');
      });
    });

    // Close the mobile menu when clicking outside
    document.addEventListener('click', function(e) {
      if (body.classList.contains('mobile-menu-open') &&
          !mobileMenuOverlay.contains(e.target) &&
          !mobileMenuButton.contains(e.target)) {
        body.classList.remove('mobile-menu-open');
        mobileMenuButton.setAttribute('aria-expanded', 'false');
      }
    });

    // Handle escape key to close menu
    document.addEventListener('keydown', function(e) {
      if (e.key === 'Escape' && body.classList.contains('mobile-menu-open')) {
        body.classList.remove('mobile-menu-open');
        mobileMenuButton.setAttribute('aria-expanded', 'false');
        mobileMenuButton.focus();
      }
    });
  
    // Handle window resize
    window.addEventListener('resize', function() {
      if (window.innerWidth >= 1024) { // md breakpoint
        mainNav.classList.remove('hidden');
        
        // Close mobile menu if open
        if (body.classList.contains('mobile-menu-open')) {
          body.classList.remove('mobile-menu-open');
          mobileMenuButton.setAttribute('aria-expanded', 'false');
        }
        
        // Reset mobile submenu styles
        document.querySelectorAll('.mobile-submenu').forEach(submenu => {
          submenu.style.maxHeight = null;
        });
        document.querySelectorAll('.submenu-toggle').forEach(toggle => {
          toggle.classList.remove('active');
        });
      } else {
        mainNav.classList.add('hidden');
      }
    });
  });